import { YupErrorMessage } from '@/constants/messages';
import { InferType, object, string } from 'yup';
import { CAREER_MAILS, REGEX_VERIFY_CODE } from '@/constants/constants';
import { multiByteCharacterValidator } from '@/utils/multiByteValidator';

export const mailVerifyScheme = object({
  mail: string()
    .trim()
    .test(
      'multi_byte_character_not_accepted',
      YupErrorMessage.string.multi_byte_character_not_accepted,
      multiByteCharacterValidator
    )
    .required(YupErrorMessage.required)
    .max(128, YupErrorMessage.string.max)
    .email(YupErrorMessage.string.email)
    .test('should_not_career_mail', YupErrorMessage.string.should_not_career_mail, (value): boolean => {
      if (value && typeof value == 'string' && value.includes('@')) {
        const separated: string | undefined = value.split('@').pop();
        if (separated) {
          return !CAREER_MAILS.includes(separated);
        }
      }
      return true;
    })
    .label('メールアドレス'),
});

export type MailVerifyFormState = InferType<typeof mailVerifyScheme>;

export const verifyCodeScheme = object({
  verifyCode: string()
    .trim()
    .required(YupErrorMessage.required)
    .matches(REGEX_VERIFY_CODE, YupErrorMessage.string.verifyCode)
    .label('認証コード'),
});

export type VerifyCodeFormState = InferType<typeof verifyCodeScheme>;
