import React from 'react';
import AccountEditMail from '@/components/organisms/Buyer/Account/Edit/Mail';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import { Wrapper } from '@/components/layout/common';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { useDirectAccess } from '@/common/hooks';
import { Routings } from '@/common/routings';
import AuthenticationCheck from '@/components/organisms/AuthenticationCheck';

const BuyerAccountEditPage: React.VFC = () => {
  const isDirectAccess = useDirectAccess(Routings.buyerTop.location);

  return isDirectAccess ? (
    <></>
  ) : (
    <AuthenticationCheck>
      <Wrapper>
        <BuyerHeader displayPattern="inquiry" />
        <AccountEditMail />
        <BuyerFooter />
      </Wrapper>
    </AuthenticationCheck>
  );
};

export default withInitializeApp(BuyerAccountEditPage);
