import React, { Dispatch, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Auth from '@aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import * as member from '@/state/modules/member';
import { Breakpoints } from '@/constants/constants';
import {
  MailVerifyFormState,
  mailVerifyScheme,
  VerifyCodeFormState,
  verifyCodeScheme,
} from '@/components/organisms/Buyer/Account/Edit/Mail/state';
import TextField from '@/components/atoms/TextField';
import { Routings } from '@/common/routings';
import { navigate } from 'gatsby';
import { PrimaryButton, SecondaryButton } from '@/components/atoms/Button';
import {
  ButtonArea,
  ErrorMessage,
  GuideParagraph,
  SubTitle,
  SubTitleWrapper,
} from '@/components/organisms/Buyer/Account/StyledComponents';
import { useFetchDoneEffect } from '@/common/hooks';
import theme from '@/styles/theme';
import { cloneDeep } from 'lodash';
import { Member, ResponseRegisterMailAddress } from 'openapi/kusabi-frontgw-spec';
import { FetchStatus } from '@/models/enum/app';
import PageTitle from '@/components/atoms/PageTitle';
import SlashIcon from '@/components/atoms/Icons/SlashIcon';
import { Main } from '@/components/layout/common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BodyArea = styled.div`
  width: 100%;
  max-width: 930px;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 10px 0 10px;
  }
`;

const FormArea = styled.div`
  width: 100%;
  max-width: 930px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 30px 0;
  border-radius: 10px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_20_40};
  margin-bottom: 40px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 30px 10px 30px 10px;
  }
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
`;

const InputField = styled(TextField)`
  height: 3em;
  width: 100%;
  max-width: 560px;
`;

const ButtonAreaMailEdit = styled(ButtonArea)`
  margin-top: 30px;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  text-align: center;
  display: flex;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 0;
    & > * {
      margin-bottom: 20px;
  }
`;

const GuideParagraphEditMail = styled(GuideParagraph)`
  align-items: center;
  width: auto;
`;

const HelperParagraph = styled(GuideParagraphEditMail)`
  font: ${theme.fonts.normal_12_20};
`;

const SecondaryButtonMailEdit = styled(SecondaryButton)`
  margin-right: 30px;
  margin-left: 30px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0;
  }
`;

const HiddenZone = styled.div`
  width: 269px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;
const WarningDescription = styled.p`
  text-align: center;
  font: ${theme.fonts.bold_22_33};
  letter-spacing: 0px;
  color: #ff0000;
  opacity: 1;
`;
type FlowProgressType = 'requireMail' | 'confirmVerifyCode' | 'completed';

const AccountEditMail = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const loggedInMember: Member = useSelector(member.selectors.getMember);
  const isRegisteredMailAddressStatus: FetchStatus = useSelector(member.selectors.fetchIsRegisteredMailAddressStatus);
  const isRegisteredMailAddress: { result: ResponseRegisterMailAddress } = useSelector(
    member.selectors.fetchIsRegisteredMailAddress
  );
  const [progress, setProgress] = useState<FlowProgressType>('requireMail');

  const {
    handleSubmit: mailVerifyHandleSubmit,
    getValues: mailVerifyGetValues,
    control: mailVerifyControl,
    reset: mailVerifyReset,
    formState: { errors: mailVerifyErrors },
  } = useForm<MailVerifyFormState>({
    mode: 'all',
    resolver: yupResolver(mailVerifyScheme),
    defaultValues: {
      mail: '',
    },
  });

  const {
    handleSubmit: verifyCodeHandleSubmit,
    getValues: verifyCodeGetValues,
    control: verifyCodeControl,
    setError: verifyCodeSetError,
    formState: { errors: verifyCodeErrors },
  } = useForm<VerifyCodeFormState>({
    mode: 'all',
    resolver: yupResolver(verifyCodeScheme),
    defaultValues: {
      verifyCode: '',
    },
  });

  useFetchDoneEffect(isRegisteredMailAddressStatus, async () => {
    dispatch(member.operations.clearIsRegisteredMailAddress());
    // すでに登録されている場合は認証画面へ遷移させる
    if (isRegisteredMailAddress.result.isRegistered) {
      setProgress('confirmVerifyCode');
      return;
    }
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { email: mailVerifyGetValues().mail })
      .then((data: string) => {
        console.log(data);
        setProgress('confirmVerifyCode');
      })
      .catch((err: string) => {
        console.warn(err);
      });
  });

  const requestVerifyCode = async () => {
    dispatch(member.operations.getIsRegisteredMailAddress({ mailAddress: mailVerifyGetValues().mail }));
  };

  const verify = async () => {
    await Auth.verifyCurrentUserAttributeSubmit('email', verifyCodeGetValues().verifyCode)
      .then((data: string) => {
        console.log(data);
        const copiedMemberData = cloneDeep(loggedInMember);
        copiedMemberData.mailAddress = mailVerifyGetValues().mail;
        dispatch(member.operations.updateMember(copiedMemberData));
        setProgress('completed');
      })
      .catch((err: string) => {
        console.warn(err);
        verifyCodeSetError('verifyCode', { type: 'invalid', message: '認証コードが違います' });
      });
  };

  const getBodyComponent = (): JSX.Element => {
    if (progress == 'requireMail') {
      return getInitialFormComponent();
    } else if (progress == 'confirmVerifyCode') {
      return getVerifyFormComponent();
    } else if (progress == 'completed') {
      return getCompleteFormComponent();
    }

    return <></>;
  };

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      navigate(Routings.login.location);
    } catch (error) {
      console.warn(error);
    }
  };

  const navigateToAccountEdit = () => {
    navigate(Routings.buyerAccount.location);
  };

  const navigateToStart = () => {
    setProgress('requireMail');
    mailVerifyReset({ mail: '' });
    navigate(Routings.buyerAccountEditMail.location);
  };

  const getInitialFormComponent = (): JSX.Element => {
    return (
      <FormArea>
        <GuideParagraphEditMail>新しく登録するメールアドレスをご入力ください。</GuideParagraphEditMail>
        <GuideParagraphEditMail>認証用メールを送信します。</GuideParagraphEditMail>
        <InputFieldWrapper>
          <Controller
            name="mail"
            control={mailVerifyControl}
            render={({ field }) => <InputField placeholder="メールアドレス" id="mail" type="text" {...field} />}
          />
        </InputFieldWrapper>
        {mailVerifyErrors?.mail && <ErrorMessage>※{mailVerifyErrors.mail?.message}</ErrorMessage>}
        <ButtonAreaMailEdit>
          <SecondaryButtonMailEdit onClick={navigateToAccountEdit}>キャンセル</SecondaryButtonMailEdit>
          <PrimaryButton onClick={mailVerifyHandleSubmit(requestVerifyCode)}>メール送信する</PrimaryButton>
          <HiddenZone />
        </ButtonAreaMailEdit>
      </FormArea>
    );
  };

  const getVerifyFormComponent = (): JSX.Element => {
    return (
      <>
        <SubTitleWrapper>
          <SlashIcon />
          <SubTitle>メールアドレス認証</SubTitle>
        </SubTitleWrapper>
        <FormArea>
        <WarningDescription>まだ登録は完了していません。画面を閉じないでください。</WarningDescription>
          <GuideParagraphEditMail>認証用メールに記載された認証コードをご入力ください。</GuideParagraphEditMail>
          <InputFieldWrapper>
            <Controller
              name="verifyCode"
              control={verifyCodeControl}
              render={({ field }) => <InputField placeholder="認証コード" id="verifyCode" type="text" {...field} />}
            />
          </InputFieldWrapper>
          {verifyCodeErrors?.verifyCode && <ErrorMessage>※{verifyCodeErrors.verifyCode?.message}</ErrorMessage>}
          <ButtonAreaMailEdit>
            <SecondaryButtonMailEdit onClick={navigateToStart}>メール再送信</SecondaryButtonMailEdit>
            <PrimaryButton onClick={verifyCodeHandleSubmit(verify)}>認証する</PrimaryButton>
            <HiddenZone />
          </ButtonAreaMailEdit>
          <HelperParagraph>
            ※ 認証コードが届かない場合は、メールアドレスをご確認の上再度ご入力ください。
          </HelperParagraph>
        </FormArea>
      </>
    );
  };

  const getCompleteFormComponent = (): JSX.Element => {
    return (
      <>
        <SubTitleWrapper>
          <SlashIcon />
          <SubTitle>メールアドレス変更完了</SubTitle>
        </SubTitleWrapper>
        <FormArea>
          <GuideParagraphEditMail>メールアドレスを変更しました</GuideParagraphEditMail>
          <ButtonAreaMailEdit>
            <PrimaryButton onClick={signOut}>ログイン画面へ</PrimaryButton>
          </ButtonAreaMailEdit>
        </FormArea>
      </>
    );
  };

  return (
    <Main>
      <PageTitle>メールアドレス変更</PageTitle>

      <Wrapper>
        <BodyArea>{getBodyComponent()}</BodyArea>
      </Wrapper>
    </Main>
  );
};

export default AccountEditMail;
